import * as React from "react";
import { CheckIcon, GlobeAltIcon, WifiIcon } from "@heroicons/react/outline";
import { LockClosedIcon } from "@heroicons/react/solid";

import PageTitle from "../components/elements/PageTitle";
import Footer from "../components/sections/Footer";
import MainSection from "../components/sections/MainSection";
import FeaturesBigSection from "../components/sections/FeaturesBigSection";
import FAQsSection from "../components/sections/FAQsSection";
import FAQItem from "../components/elements/FAQItem";
import ShortContactSection from "../components/sections/ShortContactSection";
import FeaturesBigItem from "../components/elements/FeatureBigItem";
import AdSection from "../components/sections/AdSection";
import Header from "../components/sections/Header";

const IndexSePage = () => (
  <div className="bg-white niramit-regular">
    <PageTitle pageTitle={"AxxPay"} description={``} />
    <Header lang="se" />
    <MainSection
      title="Ta betalt enkelt och smidigt med"
      imageName="axxpay-main-image-high-resolution.png"
      highlightedTitle="AxxPay"
      description="Affären finns alltid med i fickan, registrera din försäljning och ta betalt med den smartphone som du alltid bär med dig."
      firstBtnText="Kontakta oss"
      firstBtnLink="contacts"
      secondBtnText="Ladda ner från Google Play"
      secondBtnLink="/"
      brightText="Affären finns alltid med i fickan, registrera dina försäljningar och börja ta emot betalningar med den smartphone du alltid har med dig"
    />
    <FeaturesBigSection
      title="Ta betalt enkelt och smi-digt överallt och när som helst"
      description=""
    >
      <FeaturesBigItem
        name="Full trygghet"
        description="Lösningen är certifierad enligt EMV och PCI security standard och godkänd av Svenska banker, VISA, Mastercard och PCI."
        Icon={LockClosedIcon}
      />
      <FeaturesBigItem
        name="Minskad kostnad"
        description="Ingen extra hårdvara behövs då din smartphone blir en betalterminal."
        Icon={CheckIcon}
      />
      <FeaturesBigItem
        name="Flexibilitet och mobilitet"
        description="Ta betalt av dina kunder var som helst och när som helst, sälja & ta betalt med kort. Snabb installation och onboarding."
        Icon={WifiIcon}
      />
      <FeaturesBigItem
        name="Miljövänligt"
        description="Ingen extra strömförbrukning och mindre avfall."
        Icon={GlobeAltIcon}
      />
      {/* <FeaturesBigItem
        title="Always in the loop"
        description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit
              ex obcaecati natus eligendi delectus, cum deleniti sunt in labore
              nihil quod quibusdam expedita nemo."
        imgName=""
        features={[
          {
            name: "Competitive exchange rates",
            description:
              "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
            icon: GlobeAltIcon,
          },
          {
            name: "Competitive exchange rates",
            description:
              "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
            icon: GlobeAltIcon,
          },
          {
            name: "Competitive exchange rates",
            description:
              "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
            icon: GlobeAltIcon,
          },
        ]}
        reverse={true}
      /> */}
    </FeaturesBigSection>
    <AdSection
      title="Din smartphone blir din betalterminal"
      description="Med AxxPay kan vilken Android 8+ enhet som helst vara din betal- terminal. På några minuter kan du ställa in trådlösa onlinebetalningar på din enhet och enkelt integrera den i ditt företag."
      imageName="axxpay_googleplay.png"
      link=""
    />
    <FAQsSection
      title="Vanliga frågor"
      description="Läs om kraven"
      linkText="här"
      linkPath="/se/requirements/"
      imgName="axxpay-hand.png"
    >
      <FAQItem
        question="Vilka betalningsmetoder accepteras?"
        answer="AxxPay accepterar de vanligaste betalningsmetoderna, såsom Visa, Mastercard, Apple Pay, Google Pay och Samsung Pay."
      />
      <FAQItem
        question="Kan jag avbryta transaktionen och göra en återbetalning?"
        answer="Inga problem! AxxPay stödjer avbokning av betalning eller återbetalning till din kunds kort."
      />
      <FAQItem
        question="Behövs en PIN-kod?"
        answer="Vi har integrerat en certifierad PIN-kodmodul så att du kan ta emot betalningar av vilket belopp som helst."
      />
      <FAQItem
        question="Kan jag integrera AxxPay med mitt lojalitetsprogram?"
        answer="Du kan sömlöst integrera AxxPay med dina befintliga lösningar (lojalitetsprogram, pågående rabatter eller andra installerade betalsystem) eller använda AxxPay självständigt."
      />
      <FAQItem
        question="När kan jag få pengar på mitt företagsbankkonto?"
        answer="Pengarna kan hamna på ditt företagskonto nästa bankdag, beroende på avtalet med din förvärvande bank."
      />
      <FAQItem
        question="Kan jag använda en vanlig betalterminal istället för en Android-enhet?"
        answer="Ja, det kan du."
      />        
    </FAQsSection>
    <ShortContactSection
      title="Kontakta oss"
      description="Lämna din fråga i detta formulär"
      subjectMail="Short contact"
      nameLabel="Ditt namn"
      emailLabel="E-post"
      phoneLabel="Telefonnummer"
      messageLabel="Meddelande"
      submitButton="Skicka in"
      submitButtonSuccess="Skickats"
      alertTitle="Thank you!"
      alertText="We`ve received your message. Someone from our team will contact you soon"
    />
    <Footer lang="se" />
  </div>
);

export default IndexSePage;
